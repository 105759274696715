@import "~@/assets/scss/variables.scss";


























































































































.date-picker {
  width: 200px;
}
