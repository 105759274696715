@import "~@/assets/scss/variables.scss";












































































.soiling-switch-group {
  gap: 1rem;
}
