@import "~@/assets/scss/variables.scss";













































.info-icon {
  cursor: pointer;
  color: #aaa;
}

.info-icon:hover {
  color: #888;
}
