@import "~@/assets/scss/variables.scss";











































.base-switch::v-deep .custom-control-input:checked ~ .custom-control-label::before {
  background: rgba(var(--base-switch-color), 1);
  border-color: rgba(var(--base-switch-color), 1);
}

.base-switch::v-deep .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(var(--base-switch-color), 0.25);
}

.base-switch::v-deep .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: rgba(var(--base-switch-color), 0.75);
}

.base-switch::v-deep .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  background: rgba(var(--base-switch-color), 0.25);
  border-color: rgba(var(--base-switch-color), 0.1);
}
