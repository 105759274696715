@import "~@/assets/scss/variables.scss";





































































.title {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.subtitle {
  color: #777;
}

.content {
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 1px;
}
