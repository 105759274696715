@import "~@/assets/scss/variables.scss";















































































































































































































































































































.soiling-dashboard-container {
  height: calc(100vh - 105px)
}

.soiling-stats {
  column-gap: 1rem;
  row-gap: 0.5rem;
}

.soiling-stats-item {
  flex: 1 1 0;
  min-width: 310px;
  max-width: 100%;
}

@media only screen and (min-width: 576px) {
  .soiling-stats {
    column-gap: 1.5rem;
    row-gap: 1rem;
  }
  .soiling-stats-item {
    max-width: 310px;
  }
}

.min-height-fit {
  min-height: fit-content;
}

.soiling-card-title {
  font-size: 1.1rem;
}

.soiling-heatmap {
  min-height: 400px;
}

.duration-dropdown::v-deep .dropdown-toggle {
  background: white;
  font-size: 15px;
}

.duration-dropdown::v-deep .dropdown-toggle:hover, .duration-dropdown::v-deep .dropdown-toggle:active {
  background: #fafafa;
}
