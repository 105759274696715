@import "~@/assets/scss/variables.scss";






































































































































































































































































































































.heatmap-canvas {
  display: none;
}

.leaflet-control-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -50px;
  height: 110px;
  width: 130px;
  border-radius: 10px;
  background: rgb(255, 255, 255);
  z-index: 1000;
}

.heatmap-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.9rem;
  font-weight: 700;
  color: #666;
  user-select: none;
}
