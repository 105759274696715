@import "~@/assets/scss/variables.scss";






















































































































































































































































































.highcharts-container {
  min-height: 0;
  min-width: 0;
}

.min-height-fit {
  min-height: fit-content;
}

.graph-container {
  flex-basis: 0;
  min-height: 400px;
}
